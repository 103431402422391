@import "src/variables";

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #444;
}

label {
  display: block;
  margin-bottom: 4px;
}

input {
  border: 1px solid $equations-color-subtle;
  border-radius: $input-border-radius;
  padding: 10px;
}

a {
  color: #555;
  text-decoration: none;
}

.form-group {
  &:not(:last-child) {
    margin-bottom: $margin/2;
  }
}

.btn {
  padding: 10px;
  border: 1px solid $equations-color;
  color: $equations-color;
  background: none;
  border-radius: $input-border-radius;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;

  &:disabled {
    opacity: 0.5;
  }
}
