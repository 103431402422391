// colors

$equations-color: #6486a2;
$equations-color-subtle: #c3cdd6;
$tag-background: #abc0d0;

// layout

$margin: 30px;

$card-border-radius: 5px;
$input-border-radius: 7px;
$card-border-radius: 7px;
$tag-border-radius: 5px;

$max-page-width: 900px;
